import { Link } from "gatsby"
import React, {useState}  from "react"
import {animated, useTransition} from 'react-spring'
import Logo from "../images/khardware_logo.svg"
import ArrowIcon from "./Icons/ArrowIcon"
//import i18n from "../i18n"

const day = new Date().getDay()

const hoursDisplay = (day) => {
  let hours;
  if (day === 0) {
    hours = "9:00am - 4:00pm"
  } else if (day === 6) {
    hours = "9:00am - 5:00pm"
  } else {
    hours = "9:00am - 6:00pm"
  }

  return hours
}



const Header = () => {
  const [menu, setMenu] = useState(false);
 // const [lng, setLng] = useState('en');
  const transitions = useTransition(menu, null, {
    from: {transform: 'translate3d(100%, 0, 0)'},
    enter: {transform: 'translate3d(0, 0, 0)'},
    leave: {transform: 'translate3d(100%, 0, 0)'},
  })

  // useEffect(()=>{
  //   i18n.changeLanguage(lng)
  // },[lng])


  return (
  
  <header className="z-10 stick top-0 w-full xl:max-w-6xl xl:self-center">
    <div className="flex justify-between text-xs md:text-sm px-4 py-2 bg-gray-900 text-gray-100 font-customBody md:pl-8 lg:px-8 xl:px-0 " >
      <div className="md:pl-0 xl:ml-6">Opening Hours {hoursDisplay(day)}</div>
      {/* <select className="mr-2 outline-1 bg-transparent " onChange={(e)=>setLng(e.target.value)}>
        <option value="en" className="text-black">English</option>
        <option value="cy" className="text-black">Chinese</option>
      </select> */}
    </div>

    <div className="flex items-center md:justify-between md:mr-8">
    <svg onClick={()=>setMenu(!menu)} className="-mt-1 ml-4 md:hidden lg:hidden xl:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="35" height="35"><path fill="#0061F3" d="M2.5 36.5H77.5V43.5H2.5z"/><path fill="#0061F3" d="M77,37v6H3v-6H77 M78,36H2v8h76V36L78,36z"/><path fill="#0061F3" d="M2.5 16.5H77.5V23.5H2.5z"/><path fill="#0061F3" d="M77,17v6H3v-6H77 M78,16H2v8h76V16L78,16z"/><g><path fill="#0061F3" d="M2.5 56.5H77.5V63.5H2.5z"/><path fill="#7496c4" d="M77,57v6H3v-6H77 M78,56H2v8h76V56L78,56z"/></g></svg>
    <Link className="ml-4" to="/">
      <img src={Logo} alt="khardware logo" className="md:ml-2 xl:ml-0 "/>
    </Link>
    <div className="hidden sm:hidden md:block lg:block text-gray-100 font-customBody font-regular lg:pr-4 xl:pr-0 ">
    <Link className="mr-4" to="/">Home</Link>
      <Link className="mr-4" to="/about">About</Link>
      <Link to="/contact">Contact</Link>
    </div>
    </div>

    {transitions.map(({ item, key, props})=>{
      return  item && (
        <animated.div key={key} style={props} className="fixed w-full top-0 z-100 bg-gray-100 h-full">
          <svg viewBox="0 0 375 99" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0V99C0 83.8 14 74 21.0561 74H349.933C365.976 74 373.329 90.6667 375 99V0H0Z" fill="url(#paint0_linear)"/>
          <defs>
          <linearGradient id="paint0_linear" x1="187.5" y1="0" x2="187.5" y2="99" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0061F3"/>
          <stop offset="0.0001" stopColor="#0061F3"/>
          <stop offset="1" stopColor="#A31AD3"/>
          </linearGradient>
          </defs>
          </svg>
          <svg className="absolute top-0 w-6 ml-4 mt-6" onClick={()=>setMenu(!menu)} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30 3L27 0L15 12L3 0L0 3L12 15L0 27L3 30L15 18L27 30L30 27L18 15L30 3Z" fill="#FBFBFB"/>
          </svg>
  
          <div className="flex flex-col px-8 py-2 font-customBody">
          <Link className="my-2 text-xl font-bold" to='/'>
          <button onClick={()=>setMenu(!menu)} className="w-full text-left bg-lightgray p-4 rounded flex justify-between shadow mb-2 hover:bg-gray-500 active:bg-gray-500 outline-none focus:shadow-outline focus:bg-blue-500 ">Home<ArrowIcon /></button></Link>
          <Link className="my-2 text-xl font-bold" to='/about'>
          <button onClick={()=>setMenu(!menu)} className="w-full text-left bg-lightgray p-4 rounded flex justify-between shadow mb-2 hover:bg-gray-500 active:bg-gray-500 outline-none focus:shadow-outline focus:bg-blue-500">About<ArrowIcon /></button></Link>
          <Link className="my-1 text-xl font-bold" to='/contact'>
          <button onClick={()=>setMenu(!menu)} className="w-full text-left bg-lightgray p-4 rounded flex justify-between shadow mb-2 hover:bg-gray-500 active:bg-gray-500 outline-none focus:shadow-outline focus:bg-blue-500 ">Contact<ArrowIcon /></button></Link>
  
          </div>
        
        </animated.div>
      )
    })}

   
  </header>

)}



export default Header
