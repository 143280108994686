import React from 'react'

const ArrowIcon = () => {
    return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M21.205 5.00701C20.776 4.56301 20.062 4.56301 19.618 5.00701C19.189 5.43601 19.189 6.15001 19.618 6.57801L27.665 14.625H1.111C0.492 14.626 0 15.118 0 15.737C0 16.356 0.492 16.864 1.111 16.864H27.665L19.618 24.896C19.189 25.34 19.189 26.055 19.618 26.483C20.062 26.927 20.777 26.927 21.205 26.483L31.157 16.531C31.601 16.102 31.601 15.388 31.157 14.96L21.205 5.00701Z" fill="#1E201D"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="31.49" height="31.49" fill="white"/>
            </clipPath>
            </defs>
            </svg>
    )
}

export default ArrowIcon
