import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./Footer"

import "./layout.css"

const Layout = ({ children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="bg-gray-100 flex flex-col">
      <div className="flex justify-center flex-col xl:max-w-6xl  xl:self-center bg-white">
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className="xl:min-h-1/2">
          {children}
          </div>  
      <Footer/>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
