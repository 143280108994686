import React from 'react'
import { Link } from "gatsby"
import FooterBG from "../images/footerbg.svg"

const Footer = () => {
    return (
        <div className="flex flex-col pt-4 px-4 mt-16 md:pl-8 text-gray-100 xl:max-w-6xl xl:px-0 xl:self-center xl:w-full bg-cover bg-top bg-no-repeat z-10 font-customBody" style={{backgroundImage:`url(${FooterBG})`}}>
         
            <div className="relative z-10 mt-24 md:mt-16 h-full xl:pl-6">
                <div>
                    <h3 className="text-base md:text-lg">Company</h3>
                    <ul className="list-none ml-0 text-base lg:text-lg font-sans">
                        <li className="cursor-pointer"><Link to="/about">About</Link></li>
                        <li className="cursor-pointer"><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <p className="text-sm lg:text-base">Copyright Khardware 2019</p>
            </div>
           
        </div>
    )
}

export default Footer
